export default [
  {
    code: '001110',
    label:
      'CULTIVO DE CEREALES (EXCEPTO ARROZ), LEGUMBRES Y SEMILLAS OLEAGINOSAS',
    v: 4,
  },
  {
    code: '001120',
    label: 'CULTIVO DE ARROZ',
    v: 4,
  },
  {
    code: '001130',
    label: 'CULTIVO DE HORTALIZAS Y MELONES, RAÍCES Y TUBÉRCULOS.',
    v: 4,
  },
  {
    code: '001140',
    label: 'CULTIVO DE CAÑA DE AZÚCAR',
    v: 4,
  },
  {
    code: '001150',
    label: 'CULTIVO DE TABACO',
    v: 4,
  },
  {
    code: '001160',
    label: 'CULTIVO DE PLANTAS DE FIBRA',
    v: 4,
  },
  {
    code: '001190',
    label: 'CULTIVO DE OTRAS PLANTAS NO PERENNES',
    v: 4,
  },
  {
    code: '001210',
    label: 'CULTIVO DE UVA',
    v: 4,
  },
  {
    code: '001220',
    label: 'CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES',
    v: 4,
  },
  {
    code: '001230',
    label: 'CULTIVO DE  CÍTRICOS',
    v: 4,
  },
  {
    code: '001240',
    label: 'CULTIVO DE FRUTAS DE PEPITA Y DE HUESO',
    v: 4,
  },
  {
    code: '001250',
    label: 'CULTIVO DE OTROS FRUTOS Y NUECES DE ÁRBOLES Y ARBUSTOS ',
    v: 4,
  },
  {
    code: '001260',
    label: 'CULTIVO DE FRUTOS OLEAGINOSOS',
    v: 4,
  },
  {
    code: '001270',
    label: 'CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS',
    v: 4,
  },
  {
    code: '001280',
    label:
      'CULTIVO DE ESPECIAS Y DE PLANTAS AROMÁTICAS, MEDICINALES Y FARMACEUTICAS',
    v: 4,
  },
  {
    code: '001290',
    label: 'CULTIVO DE OTRAS PLANTAS NO PERENNES',
    v: 4,
  },
  {
    code: '001300',
    label: 'PROPAGACIÓN DE PLANTAS',
    v: 4,
  },
  {
    code: '001410',
    label: 'CRÍA DE GANADO BOVINO Y BÚFALOS',
    v: 4,
  },
  {
    code: '001420',
    label: 'CRÍA DE CABALLOS Y OTROS EQUINOS',
    v: 4,
  },
  {
    code: '001430',
    label: 'CRÍA DE CAMELLOS Y OTROS CAMÉLIDOS',
    v: 4,
  },
  {
    code: '001440',
    label: 'CRÍA DE OVEJAS Y CABRAS',
    v: 4,
  },
  {
    code: '001450',
    label: 'CRÍA DE CERDOS',
    v: 4,
  },
  {
    code: '001460',
    label: 'CRÍA DE AVES DE CORRAL',
    v: 4,
  },
  {
    code: '001490',
    label: 'CRÍA DE OTROS ANIMALES',
    v: 4,
  },
  {
    code: '001500',
    label:
      'CULTIVO DE PRODUCTOS AGRÌCOLAS EN COMBINACIÒN CON LA CRÌA DE ANIMALES',
    v: 4,
  },
  {
    code: '001610',
    label: 'ACTIVIDADES DE APOYO A LA AGRICULTURA',
    v: 4,
  },
  {
    code: '001620',
    label: 'ACTIVIDADES DE APOYO A LA GANADERÍA',
    v: 4,
  },
  {
    code: '001630',
    label: 'ACTIVIDADES POSCOSECHA',
    v: 4,
  },
  {
    code: '001640',
    label: 'TRATAMIENTO DE SEMILLAS PARA PROPAGACIÓN',
    v: 4,
  },
  {
    code: '001700',
    label:
      'CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS',
    v: 4,
  },
  {
    code: '002100',
    label: 'SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES',
    v: 4,
  },
  {
    code: '002200',
    label: 'EXTRACCIÓN DE MADERA',
    v: 4,
  },
  {
    code: '002300',
    label: 'RECOLECCIÓN DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA',
    v: 4,
  },
  {
    code: '002400',
    label: 'SERVICIOS DE APOYO A LA SILVICULTURA',
    v: 4,
  },
  {
    code: '003110',
    label: 'PESCA MARÍTIMA',
    v: 4,
  },
  {
    code: '003120',
    label: 'PESCA DE AGUA DULCE',
    v: 4,
  },
  {
    code: '003210',
    label: 'ACUICULTURA MARÍTIMA',
    v: 4,
  },
  {
    code: '003220',
    label: 'ACUICULTURA DE AGUA DULCE',
    v: 4,
  },
  {
    code: '005100',
    label: 'EXTRACCIÓN DE CARBÓN DE PIEDRA',
    v: 4,
  },
  {
    code: '005200',
    label: 'EXTRACCIÓN DE LIGNITO',
    v: 4,
  },
  {
    code: '006100',
    label: 'EXTRACCIÓN DE PETRÓLEO CRUDO',
    v: 4,
  },
  {
    code: '006200',
    label: 'EXTRACCIÓN DE GAS NATURAL',
    v: 4,
  },
  {
    code: '007100',
    label: 'EXTRACCIÓN DE MINERALES DE HIERRO',
    v: 4,
  },
  {
    code: '007210',
    label: 'EXTRACCIÓN DE MINERALES DE URANIO Y TORIO',
    v: 4,
  },
  {
    code: '007290',
    label: 'EXTRACCIÓN DE OTROS MINERALES METALÍFEROS NO FERROSOS',
    v: 4,
  },
  {
    code: '008100',
    label: 'EXTRACCIÓN DE PIEDRA, ARENA Y ARCILLA',
    v: 4,
  },
  {
    code: '008910',
    label:
      'EXTRACCIÓN DE MINERALES PARA LA FABRICACIÓN DE ABONOS Y PRODUCTOS QUÍMICOS',
    v: 4,
  },
  {
    code: '008920',
    label: 'EXTRACCIÓN DE TURBA',
    v: 4,
  },
  {
    code: '008930',
    label: 'EXTRACCIÓN DE SAL',
    v: 4,
  },
  {
    code: '008990',
    label: 'EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS N.C.P.',
    v: 4,
  },
  {
    code: '009100',
    label: 'ACTIVIDADES DE APOYO PARA LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL',
    v: 4,
  },
  {
    code: '009900',
    label:
      'ACTIVIDADES DE APOYO PARA OTRAS ACTIVIDADES DE EXPLOTACIÓN DE MINAS Y CANTERAS',
    v: 4,
  },
  {
    code: '010100',
    label: 'ELABORACIÒN Y CONSERVACIÓN DE CARNE',
    v: 4,
  },
  {
    code: '010200',
    label: 'ELABORACIÒN Y CONSERVACIÓN DE PESCADOS, CRUSTÁCEOS Y MOLUSCOS',
    v: 4,
  },
  {
    code: '010300',
    label: 'ELABORACIÒN Y CONSERVACIÓN DE FRUTAS,LEGUMBRES Y HORTALIZAS',
    v: 4,
  },
  {
    code: '010400',
    label: 'ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL',
    v: 4,
  },
  {
    code: '010500',
    label: 'ELABORACIÓN DE PRODUCTOS LÁCTEOS',
    v: 4,
  },
  {
    code: '010610',
    label: 'ELABORACIÓN DE PRODUCTOS DE MOLINERÍA.',
    v: 4,
  },
  {
    code: '010620',
    label: 'ELABORACIÓN DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDÓN.',
    v: 4,
  },
  {
    code: '010710',
    label: 'ELABORACIÓN DE PRODUCTOS DE PANADERÍA',
    v: 4,
  },
  {
    code: '010720',
    label: 'ELABORACIÓN DE AZÚCAR',
    v: 4,
  },
  {
    code: '010730',
    label: 'ELABORACIÓN DE CACAO Y CHOCOLATE Y DE PRODUCTOS DE CONFITERÍA',
    v: 4,
  },
  {
    code: '010740',
    label:
      'ELABORACIÓN DE MACARRONES, FIDEOS, ALCUZCUS Y PRODUCTOS FARINÁCEOS SIMILARES',
    v: 4,
  },
  {
    code: '010750',
    label: 'ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS',
    v: 4,
  },
  {
    code: '010790',
    label: 'ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS N.C.P.',
    v: 4,
  },
  {
    code: '010800',
    label: 'ELABORACIÓN DE PIENSOS PREPARADOS PARA ANIMALES',
    v: 4,
  },
  {
    code: '011010',
    label: 'DESTILACIÓN, RECTIFICACIÓN Y MEZCLA DE BEBIDAS ALCOHÓLICAS',
    v: 4,
  },
  {
    code: '011020',
    label: 'ELABORACIÓN DE VINOS',
    v: 4,
  },
  {
    code: '011030',
    label: 'ELABORACIÓN DE BEBIDAS MALTEADAS Y DE MALTA',
    v: 4,
  },
  {
    code: '011040',
    label:
      'ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS; PRODUCCIÓN DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS',
    v: 4,
  },
  {
    code: '012000',
    label: 'ELABORACIÓN DE PRODUCTOS DE TABACO',
    v: 4,
  },
  {
    code: '013110',
    label: 'PREPARACIÓN E HILATURA DE FIBRAS TEXTILES',
    v: 4,
  },
  {
    code: '013120',
    label: 'TEJEDURA DE PRODUCTOS TEXTILES',
    v: 4,
  },
  {
    code: '013130',
    label: 'ACABADO DE PRODUCTOS TEXTILES',
    v: 4,
  },
  {
    code: '013910',
    label: 'FABRICACIÓN DE TEJIDOS DE PUNTO Y GANCHILLO',
    v: 4,
  },
  {
    code: '013920',
    label:
      'FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR',
    v: 4,
  },
  {
    code: '013930',
    label: 'FABRICACIÓN DE TAPICES Y ALFOMBRAS',
    v: 4,
  },
  {
    code: '013940',
    label: 'FABRICACIÓN DE CUERDAS, CORDELES, BRAMANTES Y REDES',
    v: 4,
  },
  {
    code: '013990',
    label: 'FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.P.',
    v: 4,
  },
  {
    code: '014100',
    label: 'FABRICACIÓN DE PRENDAS DE VESTIR, EXCEPTO PRENDAS DE PIEL',
    v: 4,
  },
  {
    code: '014200',
    label: 'FABRICACIÓN DE ARTÍCULOS DE PIEL',
    v: 4,
  },
  {
    code: '014300',
    label: 'FABRICACIÓN DE ARTICULOS DE PUNTO Y GANCHILLO',
    v: 4,
  },
  {
    code: '015110',
    label: 'CURTIDO Y ADOBO DE CUEROS; ADOBO Y TEÑIDO DE PIELES',
    v: 4,
  },
  {
    code: '015120',
    label:
      'FABRICACIÓN DE MALETAS, BOLSOS DE MANO, Y ARTÍCULOS SIMILARES,Y DE ARTICULOS DE TALABARTERÍA Y GUARNICIONERÍA',
    v: 4,
  },
  {
    code: '015200',
    label: 'FABRICACIÓN DE CALZADO',
    v: 4,
  },
  {
    code: '016100',
    label: 'ASERRADOS Y ACEPILLADURA DE MADERA',
    v: 4,
  },
  {
    code: '016210',
    label:
      'FABRICACIÓN DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA',
    v: 4,
  },
  {
    code: '016220',
    label:
      'FABRICACIÓN DE PARTES Y PIEZAS DE CARPINTERÍA PARA EDIFICIOS Y CONSTRUCCIONES',
    v: 4,
  },
  {
    code: '016230',
    label: 'FABRICACIÓN DE RECIPIENTES DE MADERA',
    v: 4,
  },
  {
    code: '016290',
    label:
      'FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; FABRICACIÓN DE ARTÍCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES.',
    v: 4,
  },
  {
    code: '017010',
    label: 'FABRICACIÓN DE PASTA DE MADERA, PAPEL Y CARTÓN',
    v: 4,
  },
  {
    code: '017020',
    label:
      'FABRICACIÓN DEL PAPEL Y CARTÓN ONDULADO Y DE ENVASES DE PAPEL Y CARTÓN',
    v: 4,
  },
  {
    code: '017090',
    label: 'FABRICACIÓN DE OTROS ARTÍCULOS DEL PAPEL Y CARTÓN',
    v: 4,
  },
  {
    code: '018110',
    label: 'IMPRESIÓN',
    v: 4,
  },
  {
    code: '018120',
    label: 'ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESIÓN',
    v: 4,
  },
  {
    code: '018200',
    label: 'REPRODUCCIÓN DE GRABACIONES',
    v: 4,
  },
  {
    code: '019100',
    label: 'FABRICACIÓN DE PRODUCTOS DE HORNOS DE COQUE',
    v: 4,
  },
  {
    code: '019200',
    label: 'FABRICACIÓN DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO',
    v: 4,
  },
  {
    code: '020110',
    label: 'FABRICACIÓN DE SUSTANCIAS QUÍMICAS BÁSICAS',
    v: 4,
  },
  {
    code: '020120',
    label: 'FABRICACIÓN ABONOS Y COMPUESTOS DE NITRÓGENO',
    v: 4,
  },
  {
    code: '020130',
    label: 'FABRICACIÓN DE PLÁSTICOS Y DE CAUCHO SINTÉTICO EN FORMAS PRIMARIAS',
    v: 4,
  },
  {
    code: '020210',
    label:
      'FABRICACIÓN DE PLAGUICIDAS Y OTROS PRODUCTOS QUÍMICOS DE USO AGROPECUARIO',
    v: 4,
  },
  {
    code: '020220',
    label:
      'FABRICACIÓN DE PINTURAS, BARNICES Y PRODUCTOS DE REVESTIMIENTO SIMILARES, TINTAS DE IMPRENTA Y MASILLAS',
    v: 4,
  },
  {
    code: '020230',
    label:
      'FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR Y PULIR, PERFUMES Y PREPARADOS DE TOCADOR.',
    v: 4,
  },
  {
    code: '020290',
    label: 'FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.P.',
    v: 4,
  },
  {
    code: '020300',
    label: 'FABRICACIÓN DE FIBRAS ARTIFICIALES',
    v: 4,
  },
  {
    code: '021000',
    label:
      'FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO',
    v: 4,
  },
  {
    code: '022110',
    label:
      'FABRICACIÓN DE CUBIERTAS Y CÁMARAS DE CAUCHO; RECAUCHUTADO Y RENOVACIÓN DE CUBIERTAS DE CAUCHO',
    v: 4,
  },
  {
    code: '022190',
    label: 'FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO',
    v: 4,
  },
  {
    code: '022200',
    label: 'FABRICACIÓN DE PRODUCTOS DE PLÁSTICO',
    v: 4,
  },
  {
    code: '023100',
    label: 'FABRICACIÓN DE VIDRIO Y DE PRODUCTOS DE VIDRIO',
    v: 4,
  },
  {
    code: '023910',
    label: 'FABRICACIÓN DE PRODUCTOS REFRACTARIOS',
    v: 4,
  },
  {
    code: '023920',
    label: 'FABRICACIÓN DE MATERIALES DE CONSTRUCCIÓN DE ARCILLA',
    v: 4,
  },
  {
    code: '023930',
    label: 'FABRICACIÓN DE OTROS PRODUCTOS DE PORCELANA Y DE CERÁMICA',
    v: 4,
  },
  {
    code: '023940',
    label: 'FABRICACIÓN DE CEMENTO, CAL Y YESO',
    v: 4,
  },
  {
    code: '023950',
    label: 'FABRICACIÓN DE ARTÍCULOS DE HORMIGÓN, DE CEMENTO Y DE YESO',
    v: 4,
  },
  {
    code: '023960',
    label: 'CORTE, TALLA Y ACABADO DE LA PIEDRA',
    v: 4,
  },
  {
    code: '023990',
    label: 'FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.P.',
    v: 4,
  },
  {
    code: '046590',
    label: 'VENTA AL POR MAYOR DE OTROS TIPOS DE MAQUINARIA Y EQUIPO',
    v: 4,
  },
  {
    code: '069100',
    label: 'ACTIVIDADES JURÍDICAS',
    v: 4,
  },
  {
    code: '001000',
    label: 'ASALARIADOS',
    v: 4,
  },
  {
    code: '081000',
    label: 'SIN ACTIVIDAD ECONÓMICA, SOLO PARA PERSONAS NATURALES',
    v: 4,
  },
  {
    code: '009000',
    label: 'RENTISTAS DE CAPITAL O JUBILADOS, SÓLO PARA PERSONAS NATURALES',
    v: 4,
  },
  {
    code: '024100',
    label: 'INDUSTRIAS BÁSICAS DE HIERRO Y ACERO ',
    v: 4,
  },
  {
    code: '024200',
    label:
      'FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y OTROS METALES NO FERROSOS',
    v: 4,
  },
  {
    code: '024310',
    label: 'FUNDICIÓN DE HIERRO Y ACERO',
    v: 4,
  },
  {
    code: '024320',
    label: 'FUNDICIÓN DE METALES NO FERROSOS',
    v: 4,
  },
  {
    code: '025110',
    label: 'FABRICACIÓN DE PRODUCTOS METÁLICOS PARA USO ESTRUCTURAL',
    v: 4,
  },
  {
    code: '025120',
    label: 'FABRICACIÓN DE TANQUES, DEPÓSITOS Y RECIPIENTES DE METAL',
    v: 4,
  },
  {
    code: '025130',
    label:
      'FABRICACIÓN DE LOS GENERADORES DEL VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCIÓN CENTRAL',
    v: 4,
  },
  {
    code: '025200',
    label: 'FABRICACIÓN DE ARMAS Y MUNICIONES',
    v: 4,
  },
  {
    code: '025910',
    label: 'FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES; PULVIMETALURGIA',
    v: 4,
  },
  {
    code: '025920',
    label: 'TRATAMIENTO Y REVESTIMIENTO DE METALES; MAQUINADO',
    v: 4,
  },
  {
    code: '025930',
    label:
      'FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA, HERRAMIENTAS DE MANO Y ARTÍCULOS DE FERRETERÍA',
    v: 4,
  },
  {
    code: '025990',
    label: 'FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.',
    v: 4,
  },
  {
    code: '026100',
    label: 'FABRICACIÓN DE COMPONENTES Y TABLEROS ELECTRÓNICOS',
    v: 4,
  },
  {
    code: '026200',
    label: 'FABRICACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO',
    v: 4,
  },
  {
    code: '026300',
    label: 'FABRICACIÓN DE EQUIPOS DE COMUNICACIONES',
    v: 4,
  },
  {
    code: '026400',
    label: 'FABRICACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO',
    v: 4,
  },
  {
    code: '026510',
    label: 'FABRICACIÓN DE EQUIPO DE MEDICIÓN, PRUEBA, NAVEGACIÓN Y CONTROL',
    v: 4,
  },
  {
    code: '026520',
    label: 'FABRICACIÓN DE RELOJES',
    v: 4,
  },
  {
    code: '026600',
    label:
      'FABRICACIÓN DE EQUIPO DE IRRADIACIÓN Y EQUIPO ELECTRÓNICO DE USO MÉDICO Y TERAPÉUTICO',
    v: 4,
  },
  {
    code: '026700',
    label: 'FABRICACIÓN DE INSTRUMENTOS ÓPTICOS Y EQUIPO FOTOGRÁFICO',
    v: 4,
  },
  {
    code: '026800',
    label: 'FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS',
    v: 4,
  },
  {
    code: '027100',
    label:
      'FABRICACIÓN DE MOTORES, GENERADORES Y TRANSFORMADORES ELÉCTRICOS Y APARATOS DE DISTRIBUCIÓN Y CONTROL DE LA ENERGÍA ELÉCTRICA',
    v: 4,
  },
  {
    code: '027200',
    label: 'FABRICACIÓN DE PILAS, BATERÍAS Y ACUMULADORES',
    v: 4,
  },
  {
    code: '027310',
    label: 'FABRICACIÓN DE CABLES DE FIBRA ÓPTICA',
    v: 4,
  },
  {
    code: '027320',
    label: 'FABRICACIÓN DE OTROS HILOS Y CABLES ELÉCTRICOS',
    v: 4,
  },
  {
    code: '027330',
    label: 'FABRICACIÓN DE DISPOSITIVOS DE CABLEADO',
    v: 4,
  },
  {
    code: '027400',
    label: 'FABRICACIÓN DE EQUIPO ELÉCTRICO DE ILUMINACIÓN',
    v: 4,
  },
  {
    code: '027500',
    label: 'FABRICACIÓN DE APARATOS DE USO DOMÉSTICO',
    v: 4,
  },
  {
    code: '027900',
    label: 'FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO',
    v: 4,
  },
  {
    code: '028110',
    label:
      'FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO MOTORES PARA AERONAVES, VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS',
    v: 4,
  },
  {
    code: '028120',
    label: 'FABRICACIÓN DE EQUIPO DE PROPULSIÓN DE FLUIDOS',
    v: 4,
  },
  {
    code: '028130',
    label: 'FABRICACIÓN DE BOMBAS, COMPRESORES, GRIFOS Y VÁLVULAS',
    v: 4,
  },
  {
    code: '028140',
    label:
      'FABRICACIÓN DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISIÓN',
    v: 4,
  },
  {
    code: '028150',
    label: 'FABRICACIÓN DE HORNOS, HOGARES Y QUEMADORES',
    v: 4,
  },
  {
    code: '028160',
    label: 'FABRICACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN',
    v: 4,
  },
  {
    code: '028170',
    label:
      'FABRICACIÓN DE MAQUINARIA Y EQUIPO DE OFICINA (EXCEPTO ORDENADORES Y EQUIPO PERIFÉRICO)',
    v: 4,
  },
  {
    code: '028180',
    label: 'FABRICACIÓN DE HERRAMIENTAS DE MANO MOTORIZADAS',
    v: 4,
  },
  {
    code: '028190',
    label: 'FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO GENERAL',
    v: 4,
  },
  {
    code: '028210',
    label: 'FABRICACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL',
    v: 4,
  },
  {
    code: '028220',
    label:
      'FABRICACIÓN DE MAQUINARIA PARA LA CONFORMACIÓN DE METALES Y DE MÁQUINAS HERRAMIENTA',
    v: 4,
  },
  {
    code: '028230',
    label: 'FABRICACIÓN DE MAQUINARIA METALÚRGICA',
    v: 4,
  },
  {
    code: '028240',
    label:
      'FABRICACIÓN DE MAQUINARIA PARA EXPLOTACIÓN DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCIÓN',
    v: 4,
  },
  {
    code: '028250',
    label:
      'FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO',
    v: 4,
  },
  {
    code: '028260',
    label:
      'FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CUEROS',
    v: 4,
  },
  {
    code: '028290',
    label: 'FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL',
    v: 4,
  },
  {
    code: '029100',
    label: 'FABRICACIÓN DE VEHÍCULOS AUTOMOTORES',
    v: 4,
  },
  {
    code: '029200',
    label:
      'FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES',
    v: 4,
  },
  {
    code: '029300',
    label:
      'FABRICACIÓN DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS DE AUTOMOTORES',
    v: 4,
  },
  {
    code: '030110',
    label: 'CONSTRUCCIÓN DE BUQUES Y ESTRUCTURAS FLOTANTES',
    v: 4,
  },
  {
    code: '030120',
    label: 'CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DEPORTE',
    v: 4,
  },
  {
    code: '030200',
    label: 'FABRICACIÓN DE LOCOMOTORAS Y DE MATERIAL RODANTE',
    v: 4,
  },
  {
    code: '030300',
    label: 'FABRICACIÓN DE AERONAVES Y  NAVES ESPACIALES Y MAQUINARIA CONEXA',
    v: 4,
  },
  {
    code: '030400',
    label: 'FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE',
    v: 4,
  },
  {
    code: '030910',
    label: 'FABRICACIÓN DE MOTOCICLETAS',
    v: 4,
  },
  {
    code: '030920',
    label: 'FABRICACIÓN DE BICICLETAS Y DE SILLONES DE RUEDAS PARA INVÁLIDOS',
    v: 4,
  },
  {
    code: '030990',
    label: 'FABRICACIÓN DE OTROS TIPOS DE EQUIPO DE TRANSPORTE N.C.P.',
    v: 4,
  },
  {
    code: '031000',
    label: 'FABRICACIÓN DE MUEBLES',
    v: 4,
  },
  {
    code: '032110',
    label: 'FABRICACIÓN DE JOYAS Y ARTÍCULOS CONEXOS',
    v: 4,
  },
  {
    code: '032120',
    label: 'FABRICACIÓN DE BISUTERÍA Y ARTÍCULOS CONEXOS',
    v: 4,
  },
  {
    code: '032200',
    label: 'FABRICACIÓN DE INSTRUMENTOS DE MÚSICA',
    v: 4,
  },
  {
    code: '032300',
    label: 'FABRICACIÓN DE ARTÍCULOS DE DEPORTE',
    v: 4,
  },
  {
    code: '032400',
    label: 'FABRICACIÓN DE JUEGOS Y JUGUETES',
    v: 4,
  },
  {
    code: '032500',
    label: 'FABRICACIÓN DE INSTRUMENTOS Y MATERIALES MÉDICOS Y ODONTOLÓGICOS',
    v: 4,
  },
  {
    code: '032900',
    label: 'OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.',
    v: 4,
  },
  {
    code: '033110',
    label: 'REPARACIÓN DE PRODUCTOS ELABORADOS DE METAL',
    v: 4,
  },
  {
    code: '033120',
    label: 'REPARACIÓN DE MAQUINARIA',
    v: 4,
  },
  {
    code: '033130',
    label: 'REPARACIÓN DE EQUIPO ELECTRÓNICO Y ÓPTICO',
    v: 4,
  },
  {
    code: '033140',
    label: 'REPARACIÓN DE EQUIPO ELÉCTRICO',
    v: 4,
  },
  {
    code: '033150',
    label: 'REPARACIÓN DE EQUIPO DE TRANSPORTE, EXCEPTO VEHÍCULOS AUTOMOTORES',
    v: 4,
  },
  {
    code: '033190',
    label: 'REPARACIÓN DE OTROS TIPOS DE EQUIPO',
    v: 4,
  },
  {
    code: '033200',
    label: 'INSTALACIÓN DE MAQUINARIA Y EQUIPO INDUSTRIALES',
    v: 4,
  },
  {
    code: '035100',
    label: 'GENERACIÓN, TRANSMISIÓN Y DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA',
    v: 4,
  },
  {
    code: '035200',
    label:
      'FABRICACIÓN DEL GAS; DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍAS',
    v: 4,
  },
  {
    code: '035300',
    label: 'SUMINISTRO DE VAPOR Y AIRE ACONDICIONADO',
    v: 4,
  },
  {
    code: '036000',
    label: 'CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA',
    v: 4,
  },
  {
    code: '037000',
    label: 'EVACUACIÓN DE AGUAS RESIDUALES',
    v: 4,
  },
  {
    code: '038110',
    label: 'RECOGIDA DE DESECHOS NO PELIGROSOS',
    v: 4,
  },
  {
    code: '038120',
    label: 'RECOGIDA DE DESECHOS PELIGROSOS',
    v: 4,
  },
  {
    code: '038210',
    label: 'TRATAMIENTO Y ELIMINACIÓN DE DESECHOS NO PELIGROSOS',
    v: 4,
  },
  {
    code: '038220',
    label: 'TRATAMIENTO Y ELIMINACIÓN DE DESECHOS PELIGROSOS',
    v: 4,
  },
  {
    code: '038300',
    label: 'RECUPERACIÓN DE MATERIALES',
    v: 4,
  },
  {
    code: '039000',
    label:
      'ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS',
    v: 4,
  },
  {
    code: '041000',
    label: 'CONSTRUCCIÓN DE EDIFICIOS',
    v: 4,
  },
  {
    code: '042100',
    label: 'CONSTRUCCIÓN DE CARRETERAS Y LÍNEAS DE FERROCARRIL',
    v: 4,
  },
  {
    code: '042200',
    label: 'CONSTRUCCIÓN DE PROYECTOS DE SERVICIO PÚBLICO',
    v: 4,
  },
  {
    code: '042900',
    label: 'CONSTRUCCIÓN DE OTRAS OBRAS DE INGENIERÍA CIVIL',
    v: 4,
  },
  {
    code: '043110',
    label: 'DEMOLICIÓN',
    v: 4,
  },
  {
    code: '043120',
    label: 'PREPARACIÓN DEL TERRENO',
    v: 4,
  },
  {
    code: '043210',
    label: 'INSTALACIONES ELÉCTRICAS',
    v: 4,
  },
  {
    code: '043220',
    label: 'INSTALACIONES DE FONTANERÍA, CALEFACCIÓN Y AIRE ACONDICIONADO',
    v: 4,
  },
  {
    code: '043290',
    label: 'OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCIÓN',
    v: 4,
  },
  {
    code: '043300',
    label: 'TERMINACIÓN Y ACABADO DE EDIFICIOS',
    v: 4,
  },
  {
    code: '043900',
    label: 'OTRAS ACTIVIDADES ESPECIALIZADAS DE LA CONSTRUCCIÓN',
    v: 4,
  },
  {
    code: '045100',
    label: 'VENTA DE VEHÍCULOS AUTOMOTORES',
    v: 4,
  },
  {
    code: '045200',
    label: 'MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES',
    v: 4,
  },
  {
    code: '045300',
    label: 'VENTAS DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES',
    v: 4,
  },
  {
    code: '045400',
    label:
      'VENTA, MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS.',
    v: 4,
  },
  {
    code: '046100',
    label: 'VENTA AL POR MAYOR A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA',
    v: 4,
  },
  {
    code: '046200',
    label:
      'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y ANIMALES VIVOS.',
    v: 4,
  },
  {
    code: '046300',
    label: 'VENTA AL POR MAYOR DE ALIMENTOS, BEBIDAS Y TABACO.',
    v: 4,
  },
  {
    code: '046410',
    label:
      'VENTA AL POR MAYOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO',
    v: 4,
  },
  {
    code: '046490',
    label: 'VENTA AL POR MAYOR DE OTROS ENSERES DOMÉSTICOS',
    v: 4,
  },
  {
    code: '046510',
    label:
      'VENTA AL POR MAYOR DE ORDENADORES, EQUIPO PERIFÉRICO Y PROGRAMAS DE INFORMÁTICA',
    v: 4,
  },
  {
    code: '046520',
    label:
      'VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTRÓNICOS Y DE TELECOMUNICACIONES',
    v: 4,
  },
  {
    code: '046530',
    label:
      'VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS ',
    v: 4,
  },
  {
    code: '046610',
    label:
      'VENTA AL POR MAYOR DE COMBUSTIBLES SÓLIDOS, LÍQUIDOS Y GASEOSOS Y  PRODUCTOS CONEXOS',
    v: 4,
  },
  {
    code: '046620',
    label: 'VENTA AL POR MAYOR DE METALES Y MINERALES METALÍFEROS',
    v: 4,
  },
  {
    code: '046630',
    label:
      'VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCIÓN, ARTÍCULOS DE FERRETERÍA Y EQUIPO Y MATERIALES DE FONTANERÍA Y CALEFACCIÓN.',
    v: 4,
  },
  {
    code: '046690',
    label:
      'VENTA AL POR MAYOR DE DESPERDICIOS, DESECHOS, CHATARRA Y OTROS PRODUCTOS N.C.P ',
    v: 4,
  },
  {
    code: '046900',
    label: 'VENTA AL POR MAYOR NO ESPECIALIZADA ',
    v: 4,
  },
  {
    code: '047110',
    label:
      'VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS CON PREDOMINIO DE LA VENTA DE ALIMENTOS, BEBIDAS O TABACO',
    v: 4,
  },
  {
    code: '047190',
    label:
      'OTRAS ACTIVIDADES DE VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047210',
    label: 'VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047220',
    label: 'VENTA AL POR MENOR DE BEBIDAS EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047230',
    label:
      'VENTA AL POR MENOR DE PRODUCTOS DE TABACO EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047300',
    label:
      'VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHÍCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047410',
    label:
      'VENTA AL POR MENOR DE ORDENADORES, EQUIPO PERIFÉRICO, PROGRAMAS INFORMÁTICOS Y EQUIPO DE TELECOMUNICACIONES EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047420',
    label:
      'VENTA AL POR MENOR DE EQUIPO DE SONIDO Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047510',
    label:
      'VENTA AL POR MENOR DE PRODUCTOS TEXTILES EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047520',
    label:
      'VENTA AL POR MENOR DE ARTÍCULOS DE FERRETERÍA, PINTURAS Y PRODUCTOS DE VIDRIO EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047530',
    label:
      'VENTA AL POR MENOR DE TAPICES, ALFOMBRAS Y CUBRIMIENTOS PARA PAREDES Y PISOS EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047590',
    label:
      'VENTA AL POR MENOR DE APARATOS ELÉCTRICOS DE USO DOMÉSTICO,  MUEBLES, EQUIPO DE ILUMINACIÓN Y OTROS ENSERES DOMÉSTICOS EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047610',
    label:
      'VENTA AL POR MENOR DE LIBROS, PERIÓDICOS Y ARTÍCULOS DE PAPELERÍA EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047620',
    label:
      'VENTA AL POR MENOR DE GRABACIONES DE MÚSICA Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047630',
    label:
      'VENTA AL POR MENOR DE EQUIPO DE DEPORTE EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047640',
    label:
      'VENTA AL POR MENOR DE JUEGOS Y  JUGUETES EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047710',
    label:
      'VENTA AL POR MENOR DE PRENDAS DE VESTIR, CALZADO Y ARTÍCULOS DE CUERO EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047720',
    label:
      'VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN COMERCIOS ESPECIALIZADOS   ',
    v: 4,
  },
  {
    code: '047730',
    label:
      'VENTA AL POR MENOR DE OTROS PRODUCTOS NUEVOS EN COMERCIOS ESPECIALIZADOS',
    v: 4,
  },
  {
    code: '047740',
    label: 'VENTA AL POR MENOR DE ARTÍCULOS DE SEGUNDA MANO',
    v: 4,
  },
  {
    code: '047810',
    label:
      'VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS ',
    v: 4,
  },
  {
    code: '047820',
    label:
      'VENTA AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y MERCADOS',
    v: 4,
  },
  {
    code: '047890',
    label:
      'VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS',
    v: 4,
  },
  {
    code: '047910',
    label: 'VENTA AL POR MENOR POR CORREO Y POR INTERNET',
    v: 4,
  },
  {
    code: '047990',
    label:
      'OTRAS ACTIVIDADES DE VENTA AL POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS',
    v: 4,
  },
  {
    code: '049110',
    label: 'TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL',
    v: 4,
  },
  {
    code: '049120',
    label: 'TRANSPORTE DE CARGA POR FERROCARRIL',
    v: 4,
  },
  {
    code: '049210',
    label: 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR VÍA TERRESTRE ',
    v: 4,
  },
  {
    code: '049220',
    label: 'OTRAS ACTIVIDADES DE TRANSPORTE POR VÍA TERRESTRE',
    v: 4,
  },
  {
    code: '049230',
    label: 'TRANSPORTE DE CARGA POR CARRETERA',
    v: 4,
  },
  {
    code: '049300',
    label: 'TRANSPORTE POR TUBERÍAS',
    v: 4,
  },
  {
    code: '050110',
    label: 'TRANSPORTE DE PASAJEROS MARÍTIMO Y DE CABOTAJE',
    v: 4,
  },
  {
    code: '050120',
    label: 'TRANSPORTE DE CARGA MARÍTIMO Y DE CABOTAJE',
    v: 4,
  },
  {
    code: '050210',
    label: 'TRANSPORTE DE PASAJEROS POR VÍAS DE NAVEGACIÓN INTERIORES',
    v: 4,
  },
  {
    code: '050220',
    label: 'TRANSPORTE DE CARGA, POR VÍAS DE NAVEGACIÓN INTERIORES',
    v: 4,
  },
  {
    code: '051100',
    label: 'TRANSPORTE DE PASAJEROS POR VÍA AÉREA',
    v: 4,
  },
  {
    code: '051200',
    label: 'TRANSPORTE DE CARGA POR VÍA AÉREA',
    v: 4,
  },
  {
    code: '052100',
    label: 'ALMACENAMIENTO Y DEPÓSITO',
    v: 4,
  },
  {
    code: '052210',
    label: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE',
    v: 4,
  },
  {
    code: '052220',
    label: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACUÁTICO',
    v: 4,
  },
  {
    code: '052230',
    label: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE AÉREO',
    v: 4,
  },
  {
    code: '052240',
    label: 'MANIPULACIÓN DE CARGA',
    v: 4,
  },
  {
    code: '052290',
    label: 'OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE',
    v: 4,
  },
  {
    code: '053100',
    label: 'ACTIVIDADES POSTALES',
    v: 4,
  },
  {
    code: '053200',
    label: 'ACTIVIDADES DE MENSAJERÍA',
    v: 4,
  },
  {
    code: '055100',
    label: 'ACTIVIDADES DE ALOJAMIENTO PARA ESTANCIAS CORTAS',
    v: 4,
  },
  {
    code: '055200',
    label:
      'ACTIVIDADES DE CAMPAMENTOS, PARQUES DE VEHÍCULOS RECREATIVOS Y PARQUES DE CARAVANAS',
    v: 4,
  },
  {
    code: '055900',
    label: 'OTRAS ACTIVIDADES DE ALOJAMIENTO',
    v: 4,
  },
  {
    code: '056100',
    label: 'ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MÓVIL DE COMIDAS',
    v: 4,
  },
  {
    code: '056210',
    label: 'SUMINISTRO DE COMIDAS POR ENCARGO',
    v: 4,
  },
  {
    code: '056290',
    label: 'OTRAS ACTIVIDADES DE SERVICIO DE COMIDAS',
    v: 4,
  },
  {
    code: '056300',
    label: 'ACTIVIDADES DE SERVICIO DE BEBIDAS',
    v: 4,
  },
  {
    code: '058110',
    label: 'EDICIÓN DE LIBROS',
    v: 4,
  },
  {
    code: '058120',
    label: 'EDICIÓN DE DIRECTORIOS Y LISTAS DE CORREO',
    v: 4,
  },
  {
    code: '058130',
    label: 'EDICIÓN DE PERIÓDICOS, REVISTAS Y OTRAS PUBLICACIONES PERIÓDICAS',
    v: 4,
  },
  {
    code: '058190',
    label: 'OTRAS ACTIVIDADES DE EDICIÓN',
    v: 4,
  },
  {
    code: '058200',
    label: 'EDICIÓN DE PROGRAMAS DE INFORMÁTICOS',
    v: 4,
  },
  {
    code: '059110',
    label:
      'ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN',
    v: 4,
  },
  {
    code: '059120',
    label:
      'ACTIVIDADES DE POSTPRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN',
    v: 4,
  },
  {
    code: '059130',
    label:
      'ACTIVIDADES DE DISTRIBUCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN',
    v: 4,
  },
  {
    code: '059140',
    label:
      'ACTIVIDADES DE EXHIBICIÓN DE PELÍCULAS CINEMATOGRÁFICAS Y CINTAS DE VÍDEO ',
    v: 4,
  },
  {
    code: '059200',
    label: 'ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA',
    v: 4,
  },
  {
    code: '060100',
    label: 'TRANSMISIONES DE RADIO',
    v: 4,
  },
  {
    code: '060200',
    label: 'PROGRAMACIÓN Y TRANSMISIONES DE TELEVISIÓN',
    v: 4,
  },
  {
    code: '061100',
    label: 'ACTIVIDADES DE TELECOMUNICACIONES ALÁMBRICAS',
    v: 4,
  },
  {
    code: '061200',
    label: 'ACTIVIDADES DE TELECOMUNICACIONES INALÁMBRICAS',
    v: 4,
  },
  {
    code: '061300',
    label: 'ACTIVIDADES DE TELECOMUNICACIONES POR SATÉLITE.',
    v: 4,
  },
  {
    code: '061900',
    label: 'OTRAS ACTIVIDADES DE TELECOMUNICACIÓN.',
    v: 4,
  },
  {
    code: '062010',
    label: 'PROGRAMACIÓN INFORMÁTICA',
    v: 4,
  },
  {
    code: '062020',
    label:
      'CONSULTORÍA DE INFORMÁTICA Y DE GESTIÓN DE INSTALACIONES INFORMÁTICAS',
    v: 4,
  },
  {
    code: '062090',
    label:
      'OTRAS ACTIVIDADES DE TECNOLOGÍA DE LA INFORMACIÓN Y DE SERVICIOS INFORMÁTICOS',
    v: 4,
  },
  {
    code: '063110',
    label: 'PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS',
    v: 4,
  },
  {
    code: '063120',
    label: 'PORTALES WEB',
    v: 4,
  },
  {
    code: '063910',
    label: 'ACTIVIDADES DE AGENCIAS DE NOTICIAS',
    v: 4,
  },
  {
    code: '063990',
    label: 'OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACIÓN N.C.P.',
    v: 4,
  },
  {
    code: '064110',
    label: 'BANCA CENTRAL',
    v: 4,
  },
  {
    code: '064190',
    label: 'OTROS TIPOS DE INTERMEDIACIÓN MONETARIA.',
    v: 4,
  },
  {
    code: '064200',
    label: 'ACTIVIDADES DE SOCIEDADES DE CARTERA',
    v: 4,
  },
  {
    code: '064300',
    label: 'FONDOS Y SOCIEDADES DE INVERSIÓN Y ENTIDADES FINANCIERAS SIMILARES',
    v: 4,
  },
  {
    code: '064910',
    label: 'ARRENDAMIENTO FINANCIERO',
    v: 4,
  },
  {
    code: '064920',
    label: 'OTRAS ACTIVIDADES DE CONCESIÓN DE CRÉDITO',
    v: 4,
  },
  {
    code: '064990',
    label:
      'OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES, N.C.P.',
    v: 4,
  },
  {
    code: '065110',
    label: 'SEGUROS DE VIDA',
    v: 4,
  },
  {
    code: '065120',
    label: 'SEGUROS GENERALES',
    v: 4,
  },
  {
    code: '065200',
    label: 'REASEGUROS',
    v: 4,
  },
  {
    code: '065300',
    label: 'FONDOS DE PENSIONES',
    v: 4,
  },
  {
    code: '066110',
    label: 'ADMINISTRACIÓN DE MERCADOS FINANCIEROS',
    v: 4,
  },
  {
    code: '066120',
    label: 'CORRETAJE DE VALORES Y DE CONTRATOS DE PRODUCTOS BÁSICOS',
    v: 4,
  },
  {
    code: '066190',
    label:
      'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS',
    v: 4,
  },
  {
    code: '066210',
    label: 'EVALUACIÓN DE RIESGOS Y DAÑOS',
    v: 4,
  },
  {
    code: '066220',
    label: 'ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS',
    v: 4,
  },
  {
    code: '066290',
    label:
      'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES',
    v: 4,
  },
  {
    code: '066300',
    label: 'ACTIVIDADES DE GESTIÓN DE FONDOS',
    v: 4,
  },
  {
    code: '068100',
    label:
      'ACTIVIDADES INMOBILIARIAS REALIZADAS CON BIENES PROPIOS O ARRENDADOS',
    v: 4,
  },
  {
    code: '068200',
    label:
      'ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA',
    v: 4,
  },
  {
    code: '069200',
    label:
      'ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORÍA; CONSULTORÍA FISCAL',
    v: 4,
  },
  {
    code: '070100',
    label: 'ACTIVIDADES DE OFICINAS CENTRALES',
    v: 4,
  },
  {
    code: '070200',
    label: 'ACTIVIDADES DE CONSULTORÍA DE GESTIÓN',
    v: 4,
  },
  {
    code: '071100',
    label:
      'ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA',
    v: 4,
  },
  {
    code: '071200',
    label: 'ENSAYOS Y ANÁLISIS TÉCNICOS',
    v: 4,
  },
  {
    code: '072100',
    label:
      'INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA',
    v: 4,
  },
  {
    code: '072200',
    label:
      'INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES',
    v: 4,
  },
  {
    code: '073100',
    label: 'PUBLICIDAD',
    v: 4,
  },
  {
    code: '073200',
    label: 'ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINIÓN PÚBLICA',
    v: 4,
  },
  {
    code: '074100',
    label: 'ACTIVIDADES ESPECIALIZADAS DE DISEÑO',
    v: 4,
  },
  {
    code: '074200',
    label: 'ACTIVIDADES DE FOTOGRAFÍA',
    v: 4,
  },
  {
    code: '074900',
    label: 'OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.P.',
    v: 4,
  },
  {
    code: '075000',
    label: 'ACTIVIDADES VETERINARIAS',
    v: 4,
  },
  {
    code: '077100',
    label: 'ALQUILER Y ARRENDAMIENTO DE VEHÍCULOS AUTOMOTORES',
    v: 4,
  },
  {
    code: '077210',
    label: 'ALQUILER Y ARRENDAMIENTO DE EQUIPO RECREATIVO Y DEPORTIVO',
    v: 4,
  },
  {
    code: '077220',
    label: 'ALQUILER DE CINTAS DE VÍDEO Y DISCOS',
    v: 4,
  },
  {
    code: '077290',
    label:
      'ALQUILER Y ARRENDAMIENTO DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS ',
    v: 4,
  },
  {
    code: '077300',
    label:
      'ALQUILER Y ARRENDAMIENTO DE OTROS TIPOS DE MAQUINARIA, EQUIPO Y BIENES TANGIBLES',
    v: 4,
  },
  {
    code: '077400',
    label:
      'ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y PRODUCTOS SIMILARES, EXCEPTO OBRAS PROTEGIDAS  POR DERECHOS DE AUTOR',
    v: 4,
  },
  {
    code: '078100',
    label: 'ACTIVIDADES DE AGENCIAS DE EMPLEO',
    v: 4,
  },
  {
    code: '078200',
    label: 'ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL',
    v: 4,
  },
  {
    code: '078300',
    label: 'OTRAS ACTIVIDADES DE DOTACIÓN DE RECURSOS HUMANOS',
    v: 4,
  },
  {
    code: '079110',
    label: 'ACTIVIDADES DE AGENCIAS DE VIAJES',
    v: 4,
  },
  {
    code: '079120',
    label: 'ACTIVIDADES DE OPERADORES TURÍSTICOS',
    v: 4,
  },
  {
    code: '079900',
    label: 'OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS',
    v: 4,
  },
  {
    code: '080100',
    label: 'ACTIVIDADES DE SEGURIDAD PRIVADA',
    v: 4,
  },
  {
    code: '080200',
    label: 'ACTIVIDADES DE SERVICIO DE SISTEMAS DE SEGURIDAD',
    v: 4,
  },
  {
    code: '080300',
    label: 'ACTIVIDADES DE INVESTIGACIÓN',
    v: 4,
  },
  {
    code: '081100',
    label: 'ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES',
    v: 4,
  },
  {
    code: '081210',
    label: 'LIMPIEZA GENERAL DE EDIFICIOS',
    v: 4,
  },
  {
    code: '081290',
    label:
      'OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES',
    v: 4,
  },
  {
    code: '081300',
    label: 'ACTIVIDADES DE PAISAJISMO Y SERVICIOS DE MANTENIMIENTO CONEXOS',
    v: 4,
  },
  {
    code: '082110',
    label: 'ACTIVIDADES COMBINADAS DE SERVICIOS ADMINISTRATIVOS DE OFICINA',
    v: 4,
  },
  {
    code: '082190',
    label:
      'FOTOCOPIADO, PREPARACIÓN DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE APOYO DE OFICINA',
    v: 4,
  },
  {
    code: '082200',
    label: 'ACTIVIDADES DE CENTROS DE LLAMADAS',
    v: 4,
  },
  {
    code: '082300',
    label: 'ORGANIZACIÓN DE CONVENCIONES Y EXPOSICIONES COMERCIALES',
    v: 4,
  },
  {
    code: '082910',
    label:
      'ACTIVIDADES DE AGENCIAS DE COBRO Y AGENCIAS DE CALIFICACIÓN CREDITICIA',
    v: 4,
  },
  {
    code: '082920',
    label: 'ACTIVIDADES DE ENVASADO Y EMPAQUETADO',
    v: 4,
  },
  {
    code: '082990',
    label: 'OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P',
    v: 4,
  },
  {
    code: '084110',
    label: 'ACTIVIDADES DE LA ADMINISTRACIÓN PÚBLICA EN GENERAL',
    v: 4,
  },
  {
    code: '084120',
    label:
      'REGULACIÓN DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES Y OTROS SERVICIOS SOCIALES, EXCEPTO SERVICIOS DE SEGURIDAD SOCIAL',
    v: 4,
  },
  {
    code: '084130',
    label: 'REGULACIÓN Y FACILITACIÓN DE LA ACTIVIDAD ECONÓMICA',
    v: 4,
  },
  {
    code: '084210',
    label: 'RELACIONES EXTERIORES',
    v: 4,
  },
  {
    code: '084220',
    label: 'ACTIVIDADES DE DEFENSA',
    v: 4,
  },
  {
    code: '084230',
    label: 'ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PÚBLICO Y DE SEGURIDAD',
    v: 4,
  },
  {
    code: '084300',
    label:
      'ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA',
    v: 4,
  },
  {
    code: '085100',
    label: 'ENSEÑANZA PREESCOLAR Y PRIMARIA',
    v: 4,
  },
  {
    code: '085210',
    label: 'ENSEÑANZA SECUNDARIA DE FORMACIÓN GENERAL',
    v: 4,
  },
  {
    code: '085220',
    label: 'ENSEÑANZA SECUNDARIA DE FORMACIÓN TÉCNICA Y PROFESIONAL',
    v: 4,
  },
  {
    code: '085300',
    label: 'ENSEÑANZA SUPERIOR',
    v: 4,
  },
  {
    code: '085410',
    label: 'EDUCACIÓN DEPORTIVA Y RECREATIVA',
    v: 4,
  },
  {
    code: '085420',
    label: 'ENSEÑANZA CULTURAL',
    v: 4,
  },
  {
    code: '085490',
    label: 'OTROS TIPOS DE ENSEÑANZA N.C.P.',
    v: 4,
  },
  {
    code: '085500',
    label: 'ACTIVIDADES DE APOYO A LA ENSEÑANZA',
    v: 4,
  },
  {
    code: '086100',
    label: 'ACTIVIDADES DE HOSPITALES',
    v: 4,
  },
  {
    code: '086200',
    label: 'ACTIVIDADES DE MÉDICOS Y ODONTÓLOGOS',
    v: 4,
  },
  {
    code: '086900',
    label: 'OTRAS ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA',
    v: 4,
  },
  {
    code: '087100',
    label: 'ACTIVIDADES DE ATENCIÓN DE ENFERMERÍA EN INSTITUCIONES',
    v: 4,
  },
  {
    code: '087200',
    label:
      'ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS CON RETRASO MENTAL, ENFERMOS MENTALES Y TOXICÓMANOS',
    v: 4,
  },
  {
    code: '087300',
    label:
      'ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS DE EDAD PERSONAS CON DISCAPACIDAD',
    v: 4,
  },
  {
    code: '087900',
    label: 'OTRAS ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES',
    v: 4,
  },
  {
    code: '088100',
    label:
      'ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD',
    v: 4,
  },
  {
    code: '088900',
    label: 'OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO',
    v: 4,
  },
  {
    code: '090000',
    label: 'ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO',
    v: 4,
  },
  {
    code: '091010',
    label: 'ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS',
    v: 4,
  },
  {
    code: '091020',
    label: 'ACTIVIDADES DE MUSEOS Y GESTIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS',
    v: 4,
  },
  {
    code: '091030',
    label:
      'ACTIVIDADES DE JARDINES BOTÁNICOS Y ZOOLÓGICOS Y RESERVAS NATURALES',
    v: 4,
  },
  {
    code: '092000',
    label: 'ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS',
    v: 4,
  },
  {
    code: '093110',
    label: 'GESTIÓN DE INSTALACIONES DEPORTIVAS',
    v: 4,
  },
  {
    code: '093120',
    label: 'ACTIVIDADES DE CLUBES DEPORTIVOS',
    v: 4,
  },
  {
    code: '093190',
    label: 'OTRAS ACTIVIDADES DEPORTIVAS',
    v: 4,
  },
  {
    code: '093210',
    label: 'ACTIVIDADES DE PARQUES DE ATRACCIONES Y PARQUES TEMÁTICOS',
    v: 4,
  },
  {
    code: '093290',
    label: 'OTRAS ACTIVIDADES DE ESPARCIMIENTO Y RECREATIVAS N.C.P.',
    v: 4,
  },
  {
    code: '094110',
    label: 'ACTIVIDADES DE ASOCIACIONES EMPRESARIALES Y DE EMPLEADORES',
    v: 4,
  },
  {
    code: '094120',
    label: 'ACTIVIDADES DE ASOCIACIONES PROFESIONALES',
    v: 4,
  },
  {
    code: '094200',
    label: 'ACTIVIDADES DE SINDICATOS',
    v: 4,
  },
  {
    code: '094910',
    label: 'ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS',
    v: 4,
  },
  {
    code: '094920',
    label: 'ACTIVIDADES DE ORGANIZACIONES POLÍTICAS',
    v: 4,
  },
  {
    code: '094990',
    label: 'ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P.',
    v: 4,
  },
  {
    code: '095110',
    label: 'REPARACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO',
    v: 4,
  },
  {
    code: '095120',
    label: 'REPARACIÓN DE EQUIPOS COMUNICACIONALES',
    v: 4,
  },
  {
    code: '095210',
    label: 'REPARACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO',
    v: 4,
  },
  {
    code: '095220',
    label:
      'REPARACIÓN DE APARATOS DE USO DOMÉSTICO Y EQUIPO DOMÉSTICO Y DE JARDINERÍA',
    v: 4,
  },
  {
    code: '095230',
    label: 'REPARACIÓN DE CALZADO Y ARTÍCULOS DE CUERO',
    v: 4,
  },
  {
    code: '095240',
    label: 'REPARACIÓN DE MUEBLES Y ACCESORIOS DOMÉSTICOS',
    v: 4,
  },
  {
    code: '095290',
    label: 'REPARACIÓN DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS',
    v: 4,
  },
  {
    code: '096010',
    label:
      'LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL',
    v: 4,
  },
  {
    code: '096020',
    label: 'PELUQUERÍA Y OTROS TRATAMIENTOS DE BELLEZA',
    v: 4,
  },
  {
    code: '096030',
    label: 'POMPAS FÚNEBRES Y ACTIVIDADES CONEXAS',
    v: 4,
  },
  {
    code: '096090',
    label: 'OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P.',
    v: 4,
  },
  {
    code: '097000',
    label: 'ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO',
    v: 4,
  },
  {
    code: '099000',
    label: 'ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES',
    v: 4,
  },
]
